import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function SelfApp() {
  return <Seo title={sections.SelfApp.title} />;
}

export default SelfApp;
